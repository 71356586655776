import React from 'react';

function Container(props) {
  const { className, style, children, id } = props;

  return (
      <section className={className} style={style} id={id}>
        <div className={"container"}>
          <div className={"row"}>
              {children}
          </div>
        </div>
      </section>
  );
}

export default Container;
