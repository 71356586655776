import React from 'react';
import {Directus} from "@directus/sdk"
import config from "../config.json";
const directus = new Directus(config.DIRECTUS_URL)

class BadgeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      error: null,
    };
  }
  componentDidMount() {
    directus.items('ESGE_Badge_List').readByQuery({limit: -1, sort: ['last_name']}).then((response) => {
      this.setState({entries: response.data})
    }).catch((error) => {
      let errorMsg;
      if (error.code === 400) {
        errorMsg = "Bad Request: " + error.message;
      } else if (error.code === 401) {
        errorMsg = "Unauthorized: " + error.message;
      } else {
        errorMsg = "Error: " + error.message;
      }
      this.setState({error: errorMsg})
    })
  }
  render() {
    return (
      <div>
        <table>
          <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Company</th>
          </tr>
          </thead>
          <thead>
          {this.state.entries.map((entry, index) => (
            <tr key={index}>
              <td>{entry.last_name}</td>
              <td>{entry.first_name}</td>
              <td>{entry.company}</td>
            </tr>
          ))}
          </thead>
        </table>
        {this.state.error && <div>{this.state.error}</div>}
      </div>
    );
  }
}
export default BadgeList;
