import React from 'react';

function Footer(props) {
  // const {  } = props;

  return (
      <footer className={"bg-white"}>
        <div className={"container"}>
          <div className={"row"}>
              <div className={"col-md-6"}>
                  <ul className={"footer-menu"}>
                      <li><a href="https://hygiene.pentaxmedical.com/en/privacy-policy" target={"_blank"} rel={"noreferrer"}>Privacy Policy</a></li>
                  </ul>
              </div>
              <div className={"col-md-6 text-align-right"}>
                  <p>&copy; Copyright PENTAX Medical. All Rights Reserved.</p>
              </div>
          </div>
        </div>
      </footer>
  );
}

export default Footer;
