import React from 'react';
import {Directus} from "@directus/sdk"
import config from "../config.json";
import ReactCollapsingTable from "react-collapsing-table";
const directus = new Directus(config.DIRECTUS_URL)

class HotelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
      error: null,
    };
  }
  componentDidMount() {
    directus.items('ESGE_Hotel_List').readByQuery({limit: -1, sort: ['last_name']}).then((response) => {
      this.setState({entries: response.data})
    }).catch((error) => {
      let errorMsg;
      if (error.code === 400) {
        errorMsg = "Bad Request: " + error.message;
      } else if (error.code === 401) {
        errorMsg = "Unauthorized: " + error.message;
      } else {
        errorMsg = "Error: " + error.message;
      }
      this.setState({error: errorMsg})
    })
  }
  render() {
    return (
      <div>
        <ReactCollapsingTable
            columns={[
              { accessor: 'lastName', label: 'Last Name', priorityLevel: 1, position: 1, minWidth: 200, sortable: false, },
              { accessor: 'firstName', label: 'First Name', priorityLevel: 1, position: 2, minWidth: 200, sortable: false, },
              { accessor: 'country', label: 'Country', priorityLevel: 3, position: 3, minWidth: 300, sortable: false, },
              { accessor: 'arrivalDate', label: 'Arrival Date', priorityLevel: 4, position: 4, minWidth: 300, sortable: false, },
              { accessor: 'departureDate', label: 'Departure Date', priorityLevel: 5, position: 4, minWidth: 300, sortable: false, },
            ]}
            rows={this.state.entries.map((entry, index) => {
              return {
                id: index,
                lastName: entry.last_name,
                firstName: entry.first_name,
                country: entry.country,
                arrivalDate: entry.arrival_date,
                departureDate: entry.departure_date,
              }
            })}
            rowSize={-1}
        />
        {this.state.error && <div>{this.state.error}</div>}
      </div>
    );
  }
}
export default HotelList;
