import {Fragment, useEffect, useRef } from "react";
import React from 'react';
import { Translate } from "./Context";
import { Fade } from "@stahl.luke/react-reveal";
import Modal from 'react-modal';
// import ReactCollapsingTable from 'react-collapsing-table';

// Components
import HeaderVideo from "./components/HeaderVideo";
import Container from "./components/Container";
import ScrollTextAnimation from "./components/Scroll-Text-Animation";
import Footer from "./components/Footer";
import BadgeList from "./components/BadgeList";
import HotelList from "./components/HotelList";
import DutyList from "./components/DutyList";

// Images
import Image from "./assets/Images/pentax-medical-dublin-promenade.webp";
import HotelImage from "./assets/Images/hotel-spencer-img.png";
import BgMapImage from "./assets/Images/map.png";

const BgMap = {
    backgroundPosition: "20%",
    backgroundImage: `url(${BgMapImage})`,
}



function App() {

    /*START: INTERSECTION OBSERVER*/
    const containerRef = useRef(null)

    const   [modalBadgeIsOpen, setBadgeIsOpen] = React.useState(false),
      [modalHotelIsOpen, setHotelIsOpen] = React.useState(false),
      [modalDutyIsOpen, setDutyIsOpen] = React.useState(false);

      Modal.setAppElement('#content');
    function openBadgeModal() {
        setBadgeIsOpen(true);
    }

    function closeBadgeModal() {
        setBadgeIsOpen(false);
    }

    function openHotelModal() {
        setHotelIsOpen(true);
    }

    function closeHotelModal() {
        setHotelIsOpen(false);
    }

    function openDutyModal() {
        setDutyIsOpen(true);
    }

    function closeDutyModal() {
        setDutyIsOpen(false);
    }

    Modal.defaultStyles = {}

    useEffect(() => {
        document.title = Translate('pageTitle');
    }, []);

    return (
        <Fragment>

            <HeaderVideo/>

            <Container className={"bg-grey"}>
                <div className={"col-md-12"}>
                    <ScrollTextAnimation text={"Counting the minutes …"}/>
                </div>
                <div className={"col-md-6"}>
                    <Fade bottom duration={2000}>
                        <p>It is just one week left before our ESGE Pre-Con event starts in Dublin. Are you already wondering what you will see – apart form an extraordinary team, amazing technology and our range of exceptional speakers? Well, have a first look, now.</p>
                    </Fade>
                </div>
                <div className={"col-md-6"}>
                    <Fade bottom duration={2000}>
                        <div className={"bg-red el-spacer-big text-align-center"}>
                            <p className={"big m-b-0"}>
                                ESGE DAYS<br/>
                                PRE-CON<br/>
                                in Dublin, Ireland<br/>
                            </p>
                            <p className={"small m-b-0"}>
                                Start: 18 April 2023, 13:00 GMT (arrival in the morning)<br/>
                                End: 19 April 2023, approx. 17:00 GMT
                            </p>
                        </div>
                    </Fade>
                </div>
            </Container>

            <Container className={"bg-white bg-image"} style={BgMap}>
                <div className={"col-lg-12"}>
                    <ScrollTextAnimation text={"EXCITING AGENDA"}/>
                    <Fade bottom duration={2000}>
                        <p>This year, we have an exciting range of topics and features. This is the agenda waiting for you:</p>
                        <p><strong>Tuesday, 18th April 2023</strong></p>
                        <div className="table-wrapper">
                            <table>
                                <tbody>
                                <tr>
                                    <td><strong>Time</strong></td>
                                    <td><strong>Program</strong></td>
                                    <td><strong>Location</strong></td>
                                </tr>
                                <tr>
                                    <td>13:00 &ndash; 14:00 GMT</td>
                                    <td>Lunch</td>
                                    <td>The Spencer &ndash; Pantry</td>
                                </tr>
                                <tr>
                                    <td>14:00 &ndash; 14:30 GMT</td>
                                    <td>Briefing for team event</td>
                                    <td>The Spencer &ndash; Pantry</td>
                                </tr>
                                <tr>
                                    <td>14:30 &ndash; approx. 17:00 GMT</td>
                                    <td>Team event<br/><small>Please bring weatherproof and<br/>comfortable clothes</small></td>
                                    <td>Dublin</td>
                                </tr>
                                <tr>
                                    <td>19:00 GMT</td>
                                    <td>Meeting in the hotel lobby</td>
                                    <td>Walk to the restaurant</td>
                                </tr>
                                <tr>
                                    <td>19:30 GMT</td>
                                    <td>Dinner<br/><small>Dress code: Casual</small></td>
                                    <td>
                                        Restaurant <strong>Cleaver East,</strong><br />
                                        6-8 East Essex St,<br />
                                        Temple Bar,<br />
                                        Dublin, D02 HT44
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Fade>
                </div>
                <div className={"col-lg-12 con-m-t big-spacer"}>
                    <Fade bottom duration={2000}>
                        <p><strong>Wednesday, 19th April 2023</strong></p>
                        <div className="table-wrapper">
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <strong>Time</strong>
                                    </td>
                                    <td>
                                        <strong>Program</strong>
                                    </td>
                                    <td>
                                        <strong>Location</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        08:30 &ndash; 17:30 GMT
                                    </td>
                                    <td>
                                        Pre-Convention Meeting<br/>
                                        <small>Dress code: business casual</small>
                                    </td>
                                    <td>
                                        The Spencer - Columba
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        13:00 - 14:00 GMT<br/>
                                        15:30 - 16:00 GMT
                                    </td>
                                    <td>
                                        Showroom
                                    </td>
                                    <td>
                                        The Spencer - Pegasus
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        evening
                                    </td>
                                    <td>
                                        Enjoy Dublin &ndash; <br/> free time at your disposal
                                    </td>
                                    <td />
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </Fade>
                </div>
                <div className={"col-lg-9 con-m-t big-spacer"}>
                    <Fade bottom duration={2000}>
                        <p><strong>How to reach the hotel?</strong></p>
                        <p>Dublin Bus offers a 747 Airlink route from Dublin Airport at a charge of €7 per adult. Stop 7397, North Wall Quay, Guild Street is directly across from The Spencer so you’re just minutes from relaxation. The transfer can take up to 50 minutes depending on traffic between 3.00pm and 7.00pm, otherwise, the journey is just 20 minutes.</p>
                        <p>An alternative way you can reach The Spencer from Dublin Airport is by taxi. Just be aware of peak times to avoid traffic and additional costs. Taxis in Ireland are run on a meter so it is impossible to predict a charge in advance, though it could be anything from €20 upwards. Please be aware of the extra charge of €10 between 6.00am and 10.00am heading towards Dublin city Monday – Friday in the Port Tunnel and the extra charge when you travel from the city towards Dublin Airport between 4.00pm and 7.00pm Monday – Friday. You can download the My Taxi App to making reserving a taxi and estimating the price easier.</p>
                    </Fade>
                </div>
            </Container>

            <Container className={"bg-grey"}>
                <div className={"col-lg-12"}>
                    <ScrollTextAnimation text={"EXTRAORDINARY CITY"}/>
                </div>
                <div className={"col-lg-6"}>
                    <p>You will get to see the best spots of Dublin, a cozy city and the capital of the Republic of Ireland. During the team event you will discover the town in a different way. While others might only see the usual sightseeing spots, you will get an exclusive insider's expericence of Dublin. Have fun!</p>
                </div>
                <div className={"col-lg-6"}>
                    <img src={Image} alt={"Dublin"}/>
                </div>
            </Container>

            <div ref={containerRef}>
                <Container className={"bg-white"} id={"eventlist"}>
                    <div className={"col-lg-12"}>
                        <ScrollTextAnimation text={"EXTREMELY IMPORTANT"}/>
                    </div>
                    <div className={"col-lg-4"}>
                        <p><strong>Exclusive accommodation</strong><br/>Here you can check the final accommodation list. Please check your arrival and departure date once more! <br/>
                            <button onClick={openHotelModal} className={"btn"}>Click here to see the<br/>current accommodation list</button></p>
                        <Modal isOpen={modalHotelIsOpen} onRequestClose={closeHotelModal} contentLabel="Hotel Accomidation List">
                            <HotelList />
                        </Modal>
                    </div>
                    <div className={"col-lg-4"}>
                        <p><strong>Exhibitor badges</strong><br/>Did you register for your exhibitor badge? Please check the following final list of badges:<br/>
                            <button onClick={openBadgeModal} className={"btn"}>Click here to see the<br/>exhibitor badges list</button></p>
                        <Modal isOpen={modalBadgeIsOpen} onRequestClose={closeBadgeModal} contentLabel="Badge List">
                            <BadgeList />
                        </Modal>
                    </div>
                    <div className={"col-lg-4"}>
                        <p><strong>Exact timing</strong><br />We have assigned some of you to booth duty. Please check the plan so that you don’t miss your assignment:<br/>
                            <button onClick={openDutyModal} className={"btn"}>Click here to see the<br/>booth duty plan</button></p>
                        <Modal isOpen={modalDutyIsOpen} onRequestClose={closeDutyModal} contentLabel="Badge List">
                            <DutyList />
                        </Modal>
                    </div>
                    <div className={"col-lg-12"}>
                        <p>If you have any issues with one of the topics above, please reach out to Heike Orlando (<a href={"mailto:heike.orlando@pentaxmedical.com"}>heike.orlando@pentaxmedical.com</a>).</p>
                    </div>
                    <div className={"spacer-between-normal-and-dobule"}></div>
                </Container>
            </div>

            <Container className={"bg-grey"}>
                <div className={"col-lg-9"}>
                    <ScrollTextAnimation text={"EXTRA QUESTIONS?"}/>
                    <p>If you need support with anything regarding the ESGE Days Pre-Con or the conference, please contact Heike Orlando (<a href={"mailto:heike.orlando@pentaxmedical.com"}>heike.orlando@pentaxmedical.com</a>) as soon as possible. She will try to help you find a solution or answer.</p>
                </div>
            </Container>

            <div ref={containerRef}>
                <Container className={"bg-white bg-image"} >
                    <div className={"col-md-12"}>
                        <ScrollTextAnimation text={"Join the team in Dublin"}/>
                    </div>
                    <div className={"col-lg-6 col-xl-4"}>
                        <p><strong className={"uppercase"}>The Spencer</strong><br/>
                            Excise Walk, IFSC, Dublin 1,<br/>
                            Ireland, D01 X4C9.<br/>
                            Tel: +353 (0)1 4338800<br/>
                            Web: <a href={"https://www.thespencerhotel.com/"} target={"_blank"} rel="noreferrer">thespencerhotel.com</a>
                        </p>
                    </div>
                    <div className={"col-lg-6 col-xl-8"}>
                        <img src={HotelImage} alt={"Spencer Hotel"} className={"img-fluid"}/>
                    </div>
                </Container>
            </div>

            <Footer/>

            {/*<RotateMessage/>*/}
            {/*<ScrollAnimation animation={"test"} useBodyHeight={true}/>*/}

        </Fragment>
    );
}

export default App;
