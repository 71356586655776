import React from 'react';
import PdfDocument from "../assets/Documents/ESGE-Days-2023-responsibilities.pdf";

class DutyList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pdf: null,
      error: null,
    };
  }

  render() {
    return (
      <div id="dutyList">
        {this.state.error && <div>{this.state.error}</div>}
        <div className={"duty-header"}>
          <a className={"btn"} href={PdfDocument} rel="noreferrer" target={"_blank"}>Open PDF ESGE-Days-2023-responsibilities.pdf</a>
        </div>
        <embed src={PdfDocument} type="application/pdf" width="100%" height="100%" />
      </div>

    );
  }
}
export default DutyList;
