const defaultContext = '1';
const Context = {
    '1': {
        components: {
            eventHeader: `Blackbox Innovation Meeting<br/>in Hamburg, Germany<br/>23 – 24 May 2022`
        },
        translation: {
            pageTitle: 'ESGE DAYS PRE-CON | PENTAX Medical',
            videoIframeTitle: 'VIP Invitation. Be our special guest!',
            reserveNowHref: 'mailto:Mercedes.Canamero@PentaxMedical.com?subject=Blackbox%20Innovation%20Meeting%2C%2023%20%E2%80%93%2024%20May%202022%2C%20Hamburg%2C%20Germany&body=Dear%20PENTAX%20Medical%20team%2C%0A%0AI%20would%20like%20to%20confirm%20my%20participation%20in%20the%20Blackbox%20Innovation%20Meeting%20in%20Hamburg%2C%20Germany.%0A%0APlease%20get%20in%20touch%20with%20me%20regarding%20the%20next%20steps.%0A',
        }
    },
    '2': {
        components: {
            eventHeader: `Blackbox Innovation Meeting<br/>in Hamburg, Germany<br/>26 – 27 May 2022`
        },
        translation: {
            pageTitle: 'ESGE DAYS PRE-CON | PENTAX Medical',
            videoIframeTitle: 'VIP Invitation. Be our special guest!',
            reserveNowHref: 'mailto:Seyda.Gozcu@pentaxmedical.com;%20brandon.oberholster@pentaxmedical.com?subject=Blackbox%20Innovation%20Meeting%2C%2026%20%E2%80%93%2027%20May%202022%2C%20Hamburg%2C%20Germany&body=Dear%20PENTAX%20Medical%20team%2C%0A%0AI%20would%20like%20to%20confirm%20my%20participation%20in%20the%20Blackbox%20Innovation%20Meeting%20in%20Hamburg%2C%20Germany.%0A%0APlease%20get%20in%20touch%20with%20me%20regarding%20the%20next%20steps.%0A',
        }
    },
    '3': {
        components: {
            eventHeader: `Blackbox Innovation Meeting<br/>in Hamburg, Germany<br/>1 – 2 June 2022`
        },
        translation: {
            pageTitle: 'ESGE DAYS PRE-CON | PENTAX Medical',
            videoIframeTitle: 'VIP Invitation. Be our special guest!',
            reserveNowHref: 'mailto:andriana.niari@pentaxmedical.com;%20robert.priest@pentaxmedical.com?subject=Blackbox%20Innovation%20Meeting%2C%2001%20%E2%80%93%2002%20June%202022%2C%20Hamburg%2C%20Germany&body=Dear%20PENTAX%20Medical%20team%2C%0A%0AI%20would%20like%20to%20confirm%20my%20participation%20in%20the%20Blackbox%20Innovation%20Meeting%20in%20Hamburg%2C%20Germany.%0A%0APlease%20get%20in%20touch%20with%20me%20regarding%20the%20next%20steps.%0A',
        }
    },
    '4': {
        components: {
            eventHeader: `Blackbox Innovation Meeting<br/>in Hamburg, Germany<br/>9 – 10 June 2022`
        },
        translation: {
            pageTitle: 'ESGE DAYS PRE-CON | PENTAX Medical',
            videoIframeTitle: 'VIP Invitation. Be our special guest!',
            reserveNowHref: 'mailto:Charlotte.Shelton@pentaxmedical.com?subject=Blackbox%20Innovation%20Meeting%2C%2009%20%E2%80%93%2010%20June%202022%2C%20Hamburg%2C%20Germany&body=Dear%20PENTAX%20Medical%20team%2C%0A%0AI%20would%20like%20to%20confirm%20my%20participation%20in%20the%20Blackbox%20Innovation%20Meeting%20in%20Hamburg%2C%20Germany.%0A%0APlease%20get%20in%20touch%20with%20me%20regarding%20the%20next%20steps.%0A',
        }
    },
    '5': {
        components: {
            eventHeader: `Blackbox Innovation Meeting<br/>in Hamburg, Germany<br/>27 – 28 June 2022`
        },
        translation: {
            pageTitle: 'ESGE DAYS PRE-CON | PENTAX Medical',
            videoIframeTitle: 'VIP Invitation. Be our special guest!',
            reserveNowHref: 'mailto:john.kroeze@pentaxmedical.com?subject=Blackbox%20Innovation%20Meeting%2C%2027%20%E2%80%93%2028%20June%202022%2C%20Hamburg%2C%20Germany&body=Dear%20PENTAX%20Medical%20team%2C%0A%0AI%20would%20like%20to%20confirm%20my%20participation%20in%20the%20Blackbox%20Innovation%20Meeting%20in%20Hamburg%2C%20Germany.%0A%0APlease%20get%20in%20touch%20with%20me%20regarding%20the%20next%20steps.%0A',
        }
    },
    '6': {
        components: {
            eventHeader: `Blackbox Innovation Meeting<br/>in Hamburg, Germany<br/>5 – 6 July 2022`
        },
        translation: {
            pageTitle: 'ESGE DAYS PRE-CON | PENTAX Medical',
            videoIframeTitle: 'VIP Invitation. Be our special guest!',
            reserveNowHref: 'mailto:smahane.hebboun@pentaxmedical.com?subject=Blackbox%20Innovation%20Meeting%2C%2005%20%E2%80%93%2006%20July%202022%2C%20Hamburg%2C%20Germany&body=Dear%20PENTAX%20Medical%20team%2C%0A%0AI%20would%20like%20to%20confirm%20my%20participation%20in%20the%20Blackbox%20Innovation%20Meeting%20in%20Hamburg%2C%20Germany.%0A%0APlease%20get%20in%20touch%20with%20me%20regarding%20the%20next%20steps.%0A',
        }
    },
}

export function Component(key) {
    return <div dangerouslySetInnerHTML={{__html:Context[GetContext()].components[key]}} />;
}

export function Translate(key) {
    return Context[GetContext()].translation[key];
}

export function GetContext() {
    return GetParameter('c') || defaultContext;
}

export function GetParameter(key) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get(key);
}
